import * as React from "react";
import { Link } from "gatsby";
import "../styles/main.scss";

// markup
const NotFoundPage = () => {
  return (
    <main>
      <title>Salah Jalan</title>
      <div className="container-404">
        <h1>Salah Jalan !</h1>
        <p>
          Maaf <span role="img">😔</span> Sedang ada penutupan jalan
          <br />
          <br />
        </p>

        <Link to="/">
          <div className="back-button-border">
            <div className="back-button">Kembali Pulang</div>
          </div>
        </Link>
      </div>
    </main>
  );
};

export default NotFoundPage;
